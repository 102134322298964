import React, { FC } from 'react';
import { Box } from '@mui/material';
import { IBreadcrumb } from 'components/breadcrumbs/interfaces/IBreadcrumb';
import { BreadcrumbsItem } from 'components/breadcrumbs/BreadcrumbsItem';
import KeyboardArrowRight from 'modules/theme/components/icons/basic/KeyboardArrowRight.svg';
import { Svg } from 'modules/theme/components/svg/Svg';

interface IBreadcrumbs {
    items?: IBreadcrumb[];
    separator?: JSX.Element;
}

const SEPARATOR = (
    <Svg
        icon
        component={KeyboardArrowRight}
        width={16}
        height={16}
        color={'labelSecondary'}
    />
);

export const Breadcrumbs: FC<IBreadcrumbs> = ({
    items = [],
    separator = SEPARATOR,
}) => {
    const breadcrumbsItems = [];

    items.forEach((item, index) => {
        breadcrumbsItems.push(
            <BreadcrumbsItem title={item.title} url={item.url} />
        );
        if (items.length - 1 !== index) {
            breadcrumbsItems.push(separator);
        }
    });

    return (
        <Box
            gap={1}
            py={2}
            display={'flex'}
            paddingBottom={2}
            sx={{
                overflowX: 'auto',
            }}
        >
            {breadcrumbsItems.map((item, index) => (
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    key={`breadcrumb-item-${index}`}
                >
                    {item}
                </Box>
            ))}
        </Box>
    );
};
