'use client';

import { useCallback, useEffect } from 'react';
import { useStorage as useRtbStorage } from 'modules/analytics/rtb/hooks/util/visit/useStorage';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';

interface IReturn {
    logVisit: () => void;
    rtbEventMobile: (id: string) => void;
    rtbEventEmail: (id: string) => void;
}

const getUrlParameter = (sParam) => {
    const sPageURL = decodeURIComponent(window.location.search.substring(1));
    const sURLVariables = sPageURL.split('&');

    for (let i = 0; i < sURLVariables.length; i++) {
        const sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? null : sParameterName[1];
        }
    }
};

export const useTracker = (): IReturn => {
    const {
        get: getRtbStorage,
        set: setRtbStorage,
        isThisFirstVisitInSession,
    } = useRtbStorage();
    const rtbStorage = getRtbStorage();

    const {
        rtbHouse: { sendEvent: rtbHouseSendEvent },
    } = useAnalytics();

    const logVisit = useCallback((): void => {
        const isFromRTB = !getUrlParameter('utm_source');

        if( isThisFirstVisitInSession() && rtbStorage ){
            rtbStorage.isRecentVisitFromRTB;
            setRtbStorage({
                ...rtbStorage,
                isRecentVisitFromRTB: isFromRTB,
                wasPreviousVisitFromRTB: rtbStorage?.isRecentVisitFromRTB,
            });
        }

    }, [rtbStorage, isThisFirstVisitInSession, setRtbStorage]);

    const rtbEventMobile = useCallback((id): void => {

        rtbHouseSendEvent({
            eventType: 'conversion',
            conversionId: new Date().valueOf() + id,
            conversionClass: 'order',
            conversionSubClass: 'purchase',
            conversionCurrency: 'EUR',
            conversionValue: 1,
            offerIds: [id.toString()],
        });

    }, [rtbHouseSendEvent]);

    const rtbEventEmail = useCallback((id): void => {

        rtbHouseSendEvent({
            eventType: 'conversion',
            conversionId: new Date().valueOf() + id,
            conversionClass: 'order',
            conversionSubClass: 'purchase',
            conversionCurrency: 'EUR',
            conversionValue: 2,
            offerIds: [id.toString()],
        });

    }, [rtbHouseSendEvent]);

    useEffect(() => {
        logVisit();
    }, [logVisit]);

    return {
        logVisit,
        rtbEventMobile,
        rtbEventEmail,
    };

};
